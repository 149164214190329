<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header-inner">
        <form @submit.prevent="(page = 0), getOrder()" @keydown.enter="(page = 0), getOrder()">
          <div class="filter-card">
            <div class="filter-field">
              <label>商品名稱</label>
              <input type="text" placeholder="請輸入關鍵字" v-model.trim="search.keyword" />
            </div>

            <div class="date-field">
              <label>下單起始日</label>
              <el-date-picker
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="選擇日期"
                v-model="search.starT_DTE"
              />
            </div>

            <div class="date-field">
              <label>下單結束日</label>
              <el-date-picker
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="選擇日期"
                v-model="search.enD_DTE"
              />
            </div>

            <div class="filter-field">
              <label>訂單狀態</label>
              <select v-model="search.docX_STS">
                <option value="">全部</option>
                <option value="0">新建</option>
                <option value="X">作廢</option>
              </select>
            </div>

            <button type="submit" class="btn btn-primary btn-sm ml-1">
              查詢
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="page-body">
      <div class="row" style="font-weight: bolder">
        <div class="col-md-1 col-sm-12">
          <label>總量：</label>
          <span>{{ orderQuantity.orderTotal }}</span>
        </div>
        <div class="col-md-1 col-sm-12">
          <label>新建：</label>
          <span>{{ orderQuantity.addTotal }}</span>
        </div>
        <div class="col-md-1 col-sm-12">
          <label>作廢：</label>
          <span>{{ orderQuantity.cancelTotal }}</span>
        </div>
      </div>
      <div class="table-wrap">
        <el-table :data="orderList" :header-cell-style="{ fontWeight: 600 }" style="width: 100%">
          <el-table-column align="center" width="90px">
            <template>
              <img width="40" src="~@/assets/img/index_menu_m3.png" alt="" />
            </template>
          </el-table-column>
          <el-table-column label="訂單編號" prop="docX_NOX" />
          <el-table-column label="日期" prop="docX_DTE" />
          <el-table-column label="狀態">
            <template slot-scope="scope">
              <span v-if="scope.row.docX_STS === '0'">新建</span>
              <span v-else-if="scope.row.docX_STS === 'U'">修改中</span>
              <span v-else-if="scope.row.docX_STS === 'P'">處理中</span>
              <span v-else-if="scope.row.docX_STS === '1'">已處理</span>
              <span v-else-if="scope.row.docX_STS === '2'">已轉檔</span>
              <span v-else>作廢</span>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot-scope="scope">
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="
                  (order = {
                    salE_DTE: '',
                    docX_RMK: ''
                  }),
                    viewOrder(scope.row)
                "
              >
                檢視訂單
              </button>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot-scope="scope">
              <button type="button" class="btn btn-primary btn-sm" @click="copyOrder(scope.row)">
                複製訂單
              </button>
            </template>
          </el-table-column>
          <el-table-column slot="empty">暫無數據</el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" :total="count" @current-change="changePage" />
      </div>
    </div>

    <!-- 檢視訂單 Modal -->
    <div
      class="modal fade"
      id="orderDetailModal"
      tabindex="-1"
      role="dialog"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <p>
              訂單編號&emsp;<strong>{{ orderDetail.docX_NOX }}</strong>
            </p>
            <p>完成日期&emsp;{{ orderDetail.docX_DTE }}</p>
            <p>出貨日期&emsp;{{ orderDetail.salE_DTE }}</p>
          </div>
          <div class="modal-body">
            <div class="cart-product-list">
              <div v-for="(product, index) in orderDetail" :key="index" class="product">
                <div class="product-info">
                  <div class="product-name">
                    <h6>
                      <strong>{{ product.producT_NAME }}</strong>
                    </h6>
                  </div>
                  <div class="product-unit">
                    {{ Number(product.salE_QTY) }}{{ product.uniT_NAME }}
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-4 ml-1">備註：{{ orderDetail.docX_RMK }}</div>
          </div>
          <div class="modal-footer">
            <template v-if="orderDetail.docX_STS === '0'">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">
                關閉
              </button>
              <el-popconfirm
                title="您確定要作廢本張訂單嗎？"
                confirm-button-text="確定"
                cancel-button-text="取消"
                icon="el-icon-info"
                icon-color="red"
                @confirm="cancelOrder"
              >
                <button type="button" class="btn btn-warning" slot="reference">
                  作廢訂單
                </button>
              </el-popconfirm>
              <button type="button" class="btn btn-primary" data-dismiss="modal" @click="editOrder">
                修改訂單
              </button>
            </template>
            <template v-else>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">
                關閉
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- 編輯訂單 Modal -->
    <div
      class="modal fade"
      id="editOrderModal"
      tabindex="-1"
      role="dialog"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6>請確認品項</h6>
            <h6>共 {{ orderDetail.length }} 品項</h6>
          </div>
          <div class="modal-body">
            <div class="cart-product-list">
              <div v-for="(product, index) in orderDetail" :key="index" class="product">
                <div class="product-info">
                  <div class="product-name">{{ product.producT_NAME }}</div>
                  <div style="font-size: 13px">
                    {{ product.uniT_NAME }}&emsp;{{ product.parT_NOX }}
                  </div>
                  <input v-model="product.salE_QTY" type="number" min="1" />
                </div>
                <div class="delete">
                  <eva-icon name="trash-2-outline" fill="#FFF" @click="deleteProduct(product)" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="cancelEditOrder()"
            >
              關閉
            </button>
            <button
              v-if="orderDetail.length > 0"
              type="button"
              class="btn btn-primary"
              @click="checkCart"
            >
              確認數量
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 修改訂單 Modal-->
    <div
      class="modal fade"
      id="checkedDateModal"
      tabindex="-1"
      role="dialog"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6>
              送貨日期及留言備註
            </h6>
          </div>
          <div class="modal-body">
            <div class="field">
              <label class="w-100">
                <span style="display: block; font-weight: 600;">預計到貨日</span>
                <select class="delivery-date-style" v-model="order.salE_DTE">
                  <option
                    class="delivery-date-option"
                    v-for="(item, index) in deliveryDate"
                    :key="index"
                    :value="item"
                  >
                    {{ item.split(",")[0] + item.split(",")[1] }}
                  </option>
                </select>
              </label>
            </div>
            <div class="field">
              <label class="w-100">
                <span style="font-weight: 600;">
                  留言備註<span style="color: red">(最多僅能輸入20字)</span>
                </span>
                <textarea
                  type="text"
                  row="3"
                  maxlength="20"
                  placeholder="請輸入備註"
                  v-model="order.docX_RMK"
                />
              </label>
            </div>
            <ul style="margin-left: -22px; color: #000; font-weight: 600;">
              <li v-if="showNote" style="color: red;">依到貨日訂單累積滿${{ note }}享免運服務。</li>
              <li style="color: red;">
                實際付款金額以[銷貨單]顯示為主。
              </li>
              <li>
                當日{{
                  customerInfo.limiT_TIME
                }}前完成下單者，原則上當日配送。(偏遠地區或特殊假日除外)
              </li>
              <li>若有任何疑問請洽當區負責業務或批發中心協助。</li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              返回
            </button>
            <button type="button" class="btn btn-warning" @click.once="updateOrder">
              確認日期
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* global $ */
import { mapActions } from "vuex";
import {
  apiGetOrder,
  apiGetOrderDetail,
  apiCancelOrder,
  apiGetOrderQuantity,
  apiEditOrder,
  apiUpdateOrder,
  apiCancelEditOrder,
  apiGetEstimatedDeliveryDate
} from "@/apis/order";

export default {
  name: "OrderHistory",
  data() {
    return {
      // 客戶資訊
      customerInfo: {},

      // 查詢
      search: {},
      // 訂單數量
      orderQuantity: {},
      // 訂單總筆數
      count: 0,
      // 訂單列表
      orderList: [],

      // 訂單詳細
      orderDetail: [],

      // 訂單整理過後的商品
      products: "",

      // 訂單單號
      orderNumber: "",

      // 訂單
      order: {
        salE_DTE: "", // 送貨日期
        docX_RMK: "" // 備註
      },

      // 修改訂單的訂單編號
      docX_NOX: "",

      // 可選預計到貨日期
      deliveryDate: [],
      showNote: null,
      note: "",

      // 當前分頁
      page: 0,

      // 計時器
      timer: ""
    };
  },
  methods: {
    // 編輯訂單
    editOrder() {
      apiEditOrder({
        docX_STS: this.orderDetail.docX_STS,
        docX_NOX: this.orderDetail.docX_NOX
      }).then(response => {
        if (response.status === 200) {
          this.docX_NOX = response.data;
          $("#editOrderModal").modal("show");
        }
      });
    },

    // 更新訂單
    updateOrder() {
      this.updateLoading(true);
      this.orderDetail = this.orderDetail.filter(item => item.parT_NOX !== "99999991");
      const product = this.orderDetail.map((item, index) => {
        return {
          docX_NOX: item.docX_NOX,
          iteM_XXX: ++index,
          parT_NOX: item.parT_NOX,
          salE_QTY: item.salE_QTY
        };
      });
      apiUpdateOrder({
        account: localStorage.ACCOUNT,
        docX_NOX: this.docX_NOX,
        docX_DTE: this.dayjs().format("YYYY-MM-DD"),
        stoR_CDX: this.customerInfo.stoR_CDX,
        ordX_SRC: localStorage.USER_GUID ? "1" : "0",
        ordX_CST: localStorage.ACCOUNT,
        cusT_NOX: this.customerInfo.cusT_NOX,
        docX_STS: "0",
        docX_RMK: this.order.docX_RMK,
        salE_DTE: this.order.salE_DTE.split(",")[0],
        l_List_CTB_APP_SSWOD: product,
        VERSION: 'WEB',
      }).then(response => {
        if (response.status === 200) {
          this.updateLoading(false);
          if (response.data.errorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.errorMessage,
              type: "error",
              duration: 2500
            });
            this.order.salE_DTE = "";
            this.getEstimatedDeliveryDate();
          } else {
            $("#checkedDateModal").modal("hide");
            $("#editOrderModal").modal("hide");
            this.$notify({
              title: "成功",
              message: "修改訂單成功",
              type: "success",
              duration: 2000
            });
          }
        }
        this.updateLoading(false);
      });
    },

    // 確認數量
    checkCart() {
      const filterProduct = this.orderDetail.filter(item => item.salE_QTY < 1);
      this.products = this.orderDetail.map(item => {
        return {
          parT_NOX: item.parT_NOX,
          salE_QTY: item.salE_QTY
        };
      });
      if (filterProduct.length === 0) {
        this.order.salE_DTE = "";
        this.order.docX_RMK = "";
        this.getEstimatedDeliveryDate();
        $("#checkedDateModal").modal("show");
        this.getTimer();
      } else {
        this.$notify({
          title: "錯誤",
          message: "商品數量不得小於1",
          type: "error",
          duration: 2000
        });
      }
    },

    // 計時器
    getTimer() {
      this.timer = setTimeout(() => {
        $("#checkedDateModal").modal("hide");
      }, 1800000);
    },

    // 取得預計到貨日
    getEstimatedDeliveryDate() {
      apiGetEstimatedDeliveryDate({
        CustomerId: this.customerInfo.cusT_ID,
        Products: this.products
      }).then(response => {
        if (response.status === 200) {
          this.deliveryDate = response.data.estimatedArrivalDateList;
          this.order = {
            salE_DTE: this.deliveryDate[0]
          };
          this.showNote = response.data.isFreeShipShow;
          this.note = response.data.freeShippingLimit;
        }
      });
    },

    // 取消訂單編輯狀態
    cancelEditOrder() {
      this.updateLoading(true);
      apiCancelEditOrder({
        docX_NOX: this.orderNumber
      })
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            this.getOrder();
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // 刪除商品
    deleteProduct(product) {
      let sameProduct = this.orderDetail.findIndex(
        element => element.parT_NOX === product.parT_NOX
      );
      this.orderDetail.splice(sameProduct, 1);
    },

    // 檢視訂單
    async viewOrder(order) {
      this.orderNumber = order.docX_NOX; // 訂單編號(修改訂單用)
      this.orderDetail = await this.getOrderDetail(order.docX_NOX);
      this.orderDetail.forEach((item, index, arr) => {
        arr[index].salE_QTY = Number(item.salE_QTY);
      });
      this.$set(this.orderDetail, "docX_STS", order.docX_STS); // 訂單狀態
      this.$set(this.orderDetail, "docX_NOX", order.docX_NOX); // 訂單編號
      this.$set(this.orderDetail, "docX_DTE", this.dayjs(order.docX_DTE).format("YYYY-MM-DD")); // 完成日期
      this.$set(this.orderDetail, "salE_DTE", this.dayjs(order.salE_DTE).format("YYYY-MM-DD")); // 出貨日期
      this.$set(this.orderDetail, "docX_RMK", order.docX_RMK); // 備註
      $("#orderDetailModal").modal("show");
    },

    // 複製訂單
    async copyOrder(order) {
      const productList = await this.getOrderDetail(order.docX_NOX);
      const cart = productList.map((item, index) => {
        return {
          docX_NOX: "", // 預設傳入空字串
          iteM_XXX: ++index, // 商品項次
          parT_NOX: item.parT_NOX, // 商品項次，從1開始
          parT_NAM: item.producT_NAME, // 商品名稱
          uniT_NAME: item.uniT_NAME, // 商品單位
          salE_QTY: Number(item.salE_QTY) // 商品數量
        };
      });

      localStorage.setItem("CART", JSON.stringify(cart));
      this.$store.dispatch("getCartProductQuantity", cart.length);
    },

    // 作廢訂單
    cancelOrder() {
      $("#orderDetailModal").modal("hide");
      this.updateLoading(true);
      apiCancelOrder({
        docX_NOX: this.orderDetail.docX_NOX,
        stoR_CDX: this.customerInfo.stoR_CDX,
        cusT_NOX: this.customerInfo.cusT_NOX,
        salE_DTE: this.orderDetail.salE_DTE
      })
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            this.$notify({
              title: "成功",
              message: "訂單已作廢",
              type: "success",
              duration: 2500
            });
            this.getOrder();
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // 取得訂單列表
    getOrder() {
      this.updateLoading(true);
      apiGetOrder({
        cusT_NOX: this.customerInfo.cusT_NOX,
        stoR_CDX: this.customerInfo.stoR_CDX,
        pageIndex: this.page,
        ...this.search
      })
        .then(response => {
          if (response.status === 200) {
            this.count = response.data.count;
            this.orderList = response.data.datas;
            this.updateLoading(false);
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // 取得訂單數量
    getOrderQuantity() {
      this.updateLoading(true);
      apiGetOrderQuantity({
        cusT_NOX: this.customerInfo.cusT_NOX,
        stoR_CDX: this.customerInfo.stoR_CDX
      })
        .then(response => {
          if (response.status === 200) {
            this.orderQuantity = response.data;
            this.updateLoading(false);
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // 取得訂單詳細資訊
    getOrderDetail(docX_NOX) {
      this.updateLoading(true);
      return apiGetOrderDetail({
        docX_NOX: docX_NOX
      })
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            return response.data;
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // 換頁
    changePage(page) {
      this.page = --page;
      this.getOrder();
    },

    // loading
    ...mapActions(["updateLoading"])
  },

  created() {
    this.customerInfo = JSON.parse(localStorage.CUSTOMER_INFO);
    this.$set(
      this.search,
      "starT_DTE",
      this.dayjs()
        .subtract(3, "month")
        .format("YYYY-MM-DD")
    );
    this.$set(this.search, "enD_DTE", this.dayjs().format("YYYY-MM-DD"));
    this.$set(this.search, "docX_STS", "");
    this.$axios.all([this.getOrder(), this.getOrderQuantity()]);
  },

  destroyed() {
    clearTimeout(this.timer);
  }
};
</script>

<style lang="scss" scoped>
.delivery-date-style {
  width: 100%;
  height: 40px;
  padding-left: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-weight: 600;
  opacity: 0.7;
}

.delivery-date-style:focus-visible {
  outline: 1px solid #ccc;
}

.delivery-date-option {
  font-weight: 600;
  color: #606266;
}

textarea {
  background-color: transparent;
  outline: none;
  font-weight: 600;
  border: 1px solid #ccc;
}
.modal-header {
  p {
    cursor: default;
  }
}

/deep/ .el-table,
.el-table__expanded-cell {
  font-weight: 600;
}

/deep/ .el-select-dropdown__item {
  font-weight: 600;
}

/deep/ .el-input__inner {
  color: #000;
}
</style>
