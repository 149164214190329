import req from './https';

// 取得訂單數量
export const apiGetOrderQuantity = (params) => req('get', '/HistoryOrder/GetHistoryOrderTotalByCustNo', params);

// 取得訂單列表
export const apiGetOrder = (params) => req('post', '/HistoryOrderWeb/postHistoryOrderByCustNo', params);

// 取得訂單詳細資訊
export const apiGetOrderDetail = (params) => req('post', '/HistoryOrder/postHistoryOrderDetailByDocxNox', params);

// 取得預計到貨日選項
export const apiGetEstimatedDeliveryDate = (params) => req('post', '/Order/postEstimatedArrivalDateList', params);

// 取消訂單
export const apiCancelOrder = (params) => req('post', '/Order/postCancelOrder', params);

// 編輯訂單
export const apiEditOrder = (params) => req('post', '/Order/postBeginEditOrder', params);

// 更新訂單
export const apiUpdateOrder = (params) => req('post', '/Order/postUpdateOrder', params);

// 取消訂單編輯狀態
export const apiCancelEditOrder = (params) => req('post', '/Order/postCancelEditOrder', params);

// 建立訂單
export const apiCreateOrder = (params) => req('post', '/Order/postCreateOrder', params);

// 建立訂單加上Guid
export const apiCreateOrderGuid = (params) => req('post', '/Order/postCreateOrderWithToken', params);

// 刪除訂單
export const apiDeleteOrder = (params) => req('post', '/Order/postDeleteOrder', params);

// 成立訂單
export const apiConfirmOrder = (params) => req('post', '/Order/postConfirmOrder', params);
